import React from "react";
import "./NewArticle.css";
import saveIcon from "../assets/save_black_36dp.svg";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Loading } from "./Loading/Loading";

export class NewArticle extends React.Component {
  constructor(props) {
    super();
    this.state = {
      articleId: null,
      articleTitle: "",
      summary: [],
      article: [
        // { type: "title", text: "titre" },
        // { type: "subtitle", text: "soustitre" },
        // { type: "paragraphe", text: "cnenfezejzlfhfihzfhffefueuf" },
        // {
        //   type: "image",
        //   src: "https://assets.website-files.com/6364b6fd26e298b11fb9391f/6364b6fd26e298153ab93dbe_drawkit-tb-last.png",
        //   alt: "image illustration",
        //   file: null,
        // },
      ],
      title: "",
      subtitle: "",
      paragraphe: "",
      image: null,
      bss: "",
      onUpload: false,
      uploadFinish: false,
    };
    this.counterPromises = null;
    this.firestore = props.firestore;
    this.storage = props.storage;

    this.getLastArticleId().then((lastArticleId) => {
      this.state.articleId = lastArticleId + 1;
    });
  }

  handleClick(event, type, idOfInput) {
    this.state.summary.push(type);
    switch (type) {
      case "title":
        this.state.title !== "" &&
          this.setState({
            article: [
              ...this.state.article,
              {
                type: "title",
                text: this.state.title,
              },
            ],
          });
        this.setState({ title: "" });
        break;
      case "subtitle":
        this.state.subtitle !== "" &&
          this.setState({
            article: [
              ...this.state.article,
              {
                type: "subtitle",
                text: this.state.subtitle,
              },
            ],
          });
        this.setState({ subtitle: "" });
        break;
      case "paragraphe":
        this.state.paragraphe !== "" &&
          this.setState({
            article: [
              ...this.state.article,
              {
                type: "paragraphe",
                text: this.state.paragraphe,
              },
            ],
          });
        this.setState({ paragraphe: "" });
        break;
      case "image":
        const alt = document.getElementById("input_alt_image").value;
        if (this.state.image && alt !== "") {
          this.setState({
            article: [
              ...this.state.article,
              {
                type: "image",
                src: this.state.image.src,
                alt: alt,
                file: this.state.image.file,
              },
            ],
          });
          this.setState({ image: null });
          document.getElementById("input_alt_image").value = "";
          document.getElementById("inputImage").value = "";
        }
        break;
      default:
        break;
    }
  }

  handleChange(event, type) {
    switch (type) {
      case "title":
        this.setState({ title: event.target.value });
        break;
      case "subtitle":
        this.setState({ subtitle: event.target.value });
        break;
      case "paragraphe":
        this.setState({ paragraphe: event.target.value });
        break;
      case "image":
        if (event.target.files && event.target.files[0]) {
          this.setState({
            image: {
              src: URL.createObjectURL(event.target.files[0]),
              alt: "test",
              file: event.target.files[0],
            },
          });
        }
        break;
      case "bss":
        this.setState({ bss: event.target.value });
        break;
      default:
        break;
    }
  }

  getLastArticleId() {
    const docRef = doc(this.firestore, "articles", "lastArticleId");
    return getDoc(docRef).then((docSnap) => {
      return docSnap.data().id;
    });
  }

  uploadImages(articleToSave, jobs) {
    articleToSave.map((element) => {
      if (element.type === "image") {
        const storageRef = ref(
          this.storage,
          `articles/${"article" + this.state.articleId}/assets/${
            element.file.name
          }`
        );
        jobs.push(
          new Promise((resolve, reject) => {
            uploadBytesResumable(storageRef, element.file).then((snapshot) => {
              // download url
              getDownloadURL(snapshot.ref).then((url) => {
                debugger;
                element.url = url.split("&token")[0];
                delete element.file;
                delete element.src;

                resolve();
              });
            });
          })
        );
      }
    });
  }

  saveArticle(event) {
    console.log("saving....");
    // if the article is empty
    if (this.state.article.length === 0) {
      return;
    }

    this.setState({ onUpload: true });

    const articleToSave = [...this.state.article];
    articleToSave.unshift({
      type: "articleId",
      articleId: this.state.articleId,
    });

    const jobs = [];

    this.uploadImages(articleToSave, jobs);

    Promise.all(jobs).then(() => {
      const storageRef = ref(
        this.storage,
        `articles/${"article" + this.state.articleId}/${
          "article" + this.state.articleId
        }`
      );

      const uploadTask = uploadBytesResumable(
        storageRef,
        new Blob([JSON.stringify({ article: articleToSave })], {
          type: "application/json",
        })
      );

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          console.log(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            // const articleRef = doc(
            //   this.firestore,
            //   "articles",
            //   `article${this.state.articleId}`
            // );
            // setDoc(articleRef, { url: url });

            //window.open(`lescacaoyers.fr/articles/${this.state.articleId}`);

            const lastArticleIdRef = doc(
              this.firestore,
              "articles",
              "lastArticleId"
            );
            setDoc(lastArticleIdRef, { id: this.state.articleId });

            if (this.state.bss !== "" && this.state.bss !== "generic") {
              const articleRefInBssCollection = doc(
                this.firestore,
                "articles",
                `bss_${this.state.bss}`
              );
              updateDoc(articleRefInBssCollection, {
                articles_id: arrayUnion(this.state.articleId),
              }).then(() =>
                this.setState({ onUpload: false, uploadFinish: true })
              );
            } else {
              this.setState({ onUpload: false, uploadFinish: true });
            }
          });
        }
      );
    });
  }

  render() {
    return (
      <div id="page">
        <button id="saveButton" onClick={(e) => this.saveArticle(e)}>
          <img src={saveIcon} alt="enregistrer" />
        </button>
        <section id="edit">
          <div>
            <h3>BSS concerné</h3>
            <select
              defaultValue=""
              value={this.state.bss}
              onChange={(e) => this.handleChange(e, "bss")}
            >
              <option value="">--Choisissez une bss--</option>
              <option value="generic">Aucune</option>
              <option value="tartane">Tartane</option>
              <option value="desfort">Desfort</option>
              <option value="all">Toutes les BSS</option>
            </select>
          </div>
          <div>
            <h2>Ajouter un titre :</h2>
            <input
              id="editTitle"
              placeholder="Tapez le titre ici"
              type="text"
              value={this.state.title}
              onChange={(e) => this.handleChange(e, "title")}
            />
            <button onClick={(e) => this.handleClick(e, "title", "editTitle")}>
              Sauvegarder le titre
            </button>
          </div>
          <div>
            <h2>Ajouter un sous-titre :</h2>
            <input
              id="editSubtitle"
              placeholder="Tapez le sous-titre ici"
              type="text"
              value={this.state.subtitle}
              onChange={(e) => this.handleChange(e, "subtitle")}
            />
            <button
              onClick={(e) => this.handleClick(e, "subtitle", "editSubtitle")}
            >
              Sauvegarder le sous-titre
            </button>
          </div>
          <div id="paragrapheId">
            <h2>Ajouter un paragraphe :</h2>
            {/* <input
              id="editParagraphe"
              type="text"
              value={this.state.paragraphe}
              onChange={(e) => this.handleChange(e, "paragraphe")}
            /> */}
            <textarea
              id="editParagraphe"
              placeholder="Tapez le paragraphe ici"
              cols="50"
              aria-labelledby="your-answer-header"
              data-min-length=""
              value={this.state.paragraphe}
              onChange={(e) => this.handleChange(e, "paragraphe")}
            ></textarea>
            <button
              onClick={(e) =>
                this.handleClick(e, "paragraphe", "editParagraphe")
              }
            >
              Sauvegarder le paragraphe
            </button>
          </div>
          <div>
            <h2>Ajouter un lien : </h2>
            <input type="text" />
          </div>
          <div>
            <h2>Ajouter une image : </h2>
            <input
              id="inputImage"
              type="file"
              accept="image/*"
              onChange={(e) => this.handleChange(e, "image")}
            />
            {this.state.image && (
              <img alt="preview image" src={this.state.image.src} />
            )}
            <label for="input_alt_image">
              Ajouter une description à l'image :
            </label>
            <input
              id="input_alt_image"
              type="text"
              placeholder="Tapez la description ici"
            />
            <button onClick={(e) => this.handleClick(e, "image")}>
              Sauvegarder l'image
            </button>
          </div>
        </section>
        {!this.state.uploadFinish && !this.state.onUpload && (
          <section id="preview">
            {this.state.article.map((element) => {
              switch (element.type) {
                case "title":
                  return <h1>{element.text}</h1>;
                case "subtitle":
                  return <h2>{element.text}</h2>;
                case "paragraphe":
                  return <p>{element.text}</p>;
                case "image":
                  return <img src={element.src} alt={element.alt} />;
                default:
                  break;
              }
            })}
          </section>
        )}

        {this.state.onUpload && (
          <section id="preview">
            <Loading></Loading>
          </section>
        )}

        {this.state.uploadFinish && !this.state.onUpload && (
          <section id="preview">ARTICLE EN LIGNE !</section>
        )}
      </div>
    );
  }
}
