import logo from "./logo.svg";
import "./App.css";
import ressourcesBackOffice from "./assets/ressources-back-office.json";
import { useState } from "react";
import { EditComponent } from "./Components/EditComponent";
import { NewArticle } from "./Components/NewArticle";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

function App() {
  const [pageSelected, setPageSelected] = useState(null);
  const [pageSelectedForSection, setPageSelectedForSection] = useState("");
  const sections = ressourcesBackOffice.sections;
  const [sectionSelected, setSectionSelected] = useState(null);

  const handleChange = (event) => {
    console.log(event.target.value);
    setPageSelected(event.target.value);
    setPageSelectedForSection(
      event.target.value.substring(0, 3) === "bss" ? "bss" : event.target.value
    );
  };

  const handleChangeSection = (event) => {
    console.log(event.target.value);
    setSectionSelected(event.target.value);
  };

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyB1jWvSS4K5iMusMUv8reKWXcFk98MBhcM",
    authDomain: "bss-website-d32f2.firebaseapp.com",
    databaseURL: "https://bss-website-d32f2-default-rtdb.firebaseio.com",
    projectId: "bss-website-d32f2",
    storageBucket: "bss-website-d32f2.appspot.com",
    messagingSenderId: "535308292756",
    appId: "1:535308292756:web:a13c1a4b47abe4e16e9a81",
    measurementId: "G-4H9Z2ZM96H",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app);

  return (
    <div>
      {/* <section id="select_component">
        <label for="page-select">Choisissez une page</label>
        <select name="page" id="page-select" onChange={handleChange}>
          <option value="">--Choisir une page--</option>
          {ressourcesBackOffice.pages.map((page) => {
            return <option value={page}>{page}</option>;
          })}
        </select>
        {sections[pageSelectedForSection] && (
          <select
            name="sections"
            id="section-select"
            onChange={handleChangeSection}
          >
            <option value="">--Choisir une section--</option>
            {sections[pageSelectedForSection].map((section) => {
              return <option value={section}>{section}</option>;
            })}
          </select>
        )}
      </section>
      {pageSelected && sectionSelected && (
        <EditComponent page={pageSelected} section={sectionSelected} />
      )} */}

      <NewArticle firestore={db} storage={getStorage()} />
    </div>
  );
}

export default App;
